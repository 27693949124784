import React from "react";
import { Prop } from "../../../../types/prop.type";
import "../styles.css";
// import { useHistory } from "react-router-dom";
import CustomButton from "../../../input/Button";

const ExhbitionsCard = (prop: Prop) => {
  // const history = useHistory();
  const { status } = prop;
  const data: any = prop.data;

  return (
    <div className="">
      {data.length > 0 ? (
        data.map(({ title, imgSrc, viewers, location }: any, i: number) => (
          <div className="cardBgImg mb-5 ">
            <img
              className="object-cover"
              src={imgSrc}
              alt={title + " banner"}
            />
            <section className="flex flex-col w-full h-full">
              <div className="ml-md-5 mt-md-2 pl-2 pt-2 pl-md-5 pt-md-5 text-white">
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    lineHeight: "44px",
                  }}
                >
                  {title}
                </h4>
                <div className="d-flex">
                  <p className="pb-0 mr-2 mr-md-3">
                    <img
                      className="img-fluid mr-2"
                      src="/icons/viewers.svg"
                      alt="viewers"
                    />
                    <span className="d-inline-block">{viewers} viewers</span>
                  </p>
                  <p className="">
                    <img
                      className="img-fluid mr-2"
                      src="/icons/location.svg"
                      alt="location"
                    />
                    <span className="d-inline-block">{location}</span>
                  </p>
                </div>
                <div className="d-flex">
                  {status === "live" && (
                    <div className="centerItems mr-2" key={i}>
                      <img
                        className="img-fluid mr-2"
                        src="/icons/live-indicator.svg"
                        alt="live-indicator"
                      />
                      {String(status).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="d-flex">
                  <CustomButton
                    className="watchLiveBtn px-md-3 py-2 my-2 "
                    label="Watch Live Video"
                    type="button"
                  />
                  <CustomButton
                    className="createBtn px-3 py-2 my-2 ml-5"
                    label="Watch in 3D"
                    type="button"
                  />
                </div>
              </div>
            </section>
          </div>
        ))
      ) : (
        <h6 className="text-center my-4">No records found!</h6>
      )}
    </div>
  );
};

export default ExhbitionsCard;
