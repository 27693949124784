import React from "react";
import Button from "@material-ui/core/Button";
import { Prop } from "../../types/prop.type";

function CustomButton(prop: Prop) {
  return (
    <Button
      style={{...prop.style}}
      disableElevation={true}
      type={prop.type || "button"}
      variant={prop.outline ? "outlined" : "contained"}
      className={"a-loginFormButton " + prop.className}
      onClick={() => (prop.action ? prop.action() : null)}
    >
      {prop.label}
    </Button>
  );
}

export default CustomButton;
