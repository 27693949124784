import React from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";

export default function DetailLayout({ children }: { children: any }) {
  const history: any = useHistory();

  return (
    <div className="o-dashboard container-fluid">
      <div className="row">
        <div className="" style={{ width: "fit-content" }}>
          <KeyboardBackspace
            onClick={() => history.goBack()}
            className="--pointer m-2"
          />
        </div>
        <div className="container col">{children}</div>
      </div>
    </div>
  );
}
