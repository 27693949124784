import React from "react";
import "./styles.css";
import CustomButton from "../../input/Button";
import GalleryTab from "./sub-components/GalleryTab";

export default function GalleryLayout() {
  return (
    <>
      <p>
        <img
          src="/icons/location.svg"
          alt="location"
          className="mr-2"
          style={{ width: "24px" }}
        />
        No. 2 Oba Yekini Elegushi Rd, Lekki Phase I, Lekki, Lagos
      </p>
      <p>
        Nike Art Gallery is an art gallery in Lagos owned by Nike
        Davies-Okundaye. The gallery is arguably the largest of its kind in West
        Africa. Housed in a five-storey tall building, it boasts a collection of
        about 8,000 diverse artworks from various Nigerian artists.
      </p>

      <GalleryTab
        collection={[
          {
            title: "MALCOLMSON COLLECTION",
            details:
              "The Malcolmson Collection spans the history of photography from the 1840s, with works by inventor William Fox Talbot, to the 2000s, with works by contemporary artists Scott McFarland and Ian Wallace, among others, and includes representative photographs from each of the significant periods and styles of the history of the medium. The collection highlights photographers known for creating a unique photographic visual language, including Eugène Atget, Harry Callahan, Julia Margaret Cameron, László Moholy-Nagy, Man Ray, Aleksandr Rodchenko, John Vanderpant and Edward Weston.",
            bid: null,
            img: "/images/pic1.png",
            purchased: false,
          },
          {
            title: "THE STORY OF THE EYES",
            details: "Glossed art print, 70 × 45 cm",
            bid: "$ 150",
            img: "/images/pic1.png",
            purchased: true,
          },
        ].map((work, i: number) => {
          return (
            <div className="row py-3" key={i}>
              <div className="col-sm-4">
                <img src={work.img} alt="work" className="img-fluid" />
              </div>
              <div className="col-sm-8">
                <h5>{work.title}</h5>
                <p>{work.details}</p>
                {work.purchased === true ? <h6>{work.bid} Purchased</h6> : ""}

                <CustomButton
                  className="--yellowBg text-white px-4"
                  label={work.purchased === true ? "View" : "View Collection"}
                  type="button"
                  // action={}
                />
              </div>
            </div>
          );
        })}
        artists={
          <div className="row">
            {[1, 2, 3, 4, 5, 6].map((artist, i: number) => (
              <div className="col-sm-6 col-md-4 p-2" key={i}>
                <div className="d-flex flex-column">
                  <img src="/images/pic2.png" alt="" className="img-fluid" />
                  <span>VAN GOGH</span>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </>
  );
}
