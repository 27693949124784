import React, { useState } from "react";
import { FormControl } from "@material-ui/core";

const AuctionsCreateForm = () => {
  const [file, setFile] = useState(null);
  // Handler functions
  const handleFile = (e: any) => setFile(e.target.files[0]);
  // const handleFile = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files[0])

  return (
    <div className="mt-3 mb-5">
      <div className="--pointer">
        <label
          title="Upload File"
          className="--pointer fileUpload flex-column py-5 centerItems  w-100"
          htmlFor="fileUpload"
        >
          <img src="/icons/upload.svg" className="--pointer" alt="upload" />
          <span className="addArtwork">
            Upload images, videos of your artwork
          </span>
          <input
            className="d-none"
            id="fileUpload"
            name="fileUpload"
            type="file"
            accept="image/*,video/*"
            onChange={handleFile}
          />
          {/* <span className="relative">
                    {file && (
                      <span className={styles.selectedFileIndicator}>1</span>
                    )}
                  </span> */}
        </label>
        <div className="container-fluid">
          <div className="row">
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="title">
                <h6 className="my-2">Title of ArtWork</h6>
                <input type="text" id="title" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-12">
              <label htmlFor="desc">
                <h6 className="my-2">Description</h6>
                <textarea id="desc" className="w-100" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="size">
                <h6 className="my-2">Frame Size (e.g. 70 × 45cm) </h6>
                <input type="text" id="size" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pl-md-3">
              <label htmlFor="category">
                <h6 className="my-2">Category</h6>
                <input type="text" id="category" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="price">
                <h6 className="my-2">On Sale At</h6>
                <input type="text" id="price" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pl-md-3">
              <label htmlFor="minBid">
                <h6 className="my-2">Minimum bid</h6>
                <input type="text" id="minBid" className="w-100 a-inputField" />
              </label>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionsCreateForm;
