import React, { useEffect } from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import DatabaseTable from "./sub-components/DatabaseTable";
import { Link } from "react-router-dom";
import { getUsersData } from "../actions";
import { useDispatch } from "react-redux";

function DatabaseList() {
  // Dispatch
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(getUsersData());
  }, []);
  
  const renderDatabase = () => {
    return (
      <div className="o-dashboard">
        <div className="container">
          <Link to="/#">Check All</Link>
          <DatabaseTable />
        </div>
      </div>
    )
  };

  return (<DashboardLayout >{ renderDatabase() }</DashboardLayout>);
}

export default DatabaseList;
