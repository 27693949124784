import React, { useEffect } from "react";
import { Prop } from "../../../../types/prop.type";
import "../styles.css";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../input/Button";
import {
  getAuctionsData,
  getAuctionRequestsData,
  getAuctionOngoingData,
  getAuctionUpcomingData,
  getAuctionPastData,
  approveAuction,
  endAuction,
  deleteAuction,
  declineAuction,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../../../store";

export interface Constants {
  [key: string]: any;
}

const AuctionsCard = (prop: Prop) => {
  const history = useHistory();
  // State management
  const {
    auctions,
    requestAuctions,
    ongoingAuctions,
    upcomingAuctions,
    pastAuctions,
  } = useSelector((store: InitialState) => store.auctions);
  // constants
  const status: string = prop.status;
  const actions: Constants = {
    all: getAuctionsData,
    requests: getAuctionRequestsData,
    ongoing: getAuctionOngoingData,
    upcoming: getAuctionUpcomingData,
    past: getAuctionPastData,
  };
  const data: Constants = {
    all: auctions,
    requests: requestAuctions,
    ongoing: ongoingAuctions,
    upcoming: upcomingAuctions,
    past: pastAuctions,
  };
  // Dispatch
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(actions[status] ? actions[status]() : { type: "LOAD_ERROR" });
  }, []);

  // Action handlers
  const handleAction = (action: string, param: string) => {
    switch (action) {
      case "approve":
        dispatch(approveAuction(param));
        break;
      case "decline":
        dispatch(declineAuction(param));
        break;
      case "end-auction":
        dispatch(endAuction(param));
        break;
      case "delete":
        dispatch(deleteAuction(param));
        break;
      default:
        break;
    }
  };

  console.log(status);

  const goToAuction = (auction: any) => {
    if (status !== "ongoing")
      return history.push("/auctions/detail", { auction });
    history.push("/auctions/overview", { auction });
  };

  const getDateText = (date: string) => {
    return status === "requests" || status === "upcoming" ? (
      <p className="cardText">
        Date: <strong>{date}</strong>
      </p>
    ) : status === "ongoing" ? (
      <p className="cardText">
        5 Bids | ENDS BY: <strong>{date}</strong>
      </p>
    ) : (
      <p></p>
    );
  };

  const getViewBtn = (auction: any) => {
    return (
      <CustomButton
        className="viewBtn px-5 py-2 my-2"
        label={status === "past" ? "View Auctioneers" : "View"}
        type="button"
        action={() => goToAuction({ auction, status })}
      />
    );
  };

  return (
    <div className="container-fluid">
      {data?.[status].length > 0 ? (
        data?.[status].map((auction: any, i: number) => (
          <div
            className="row p-3"
            style={{ borderBottom: "0.6px solid #C4C4C4" }}
            key={i}
          >
            <div className="col-md-4">
              <img
                src={auction?.artwork?.files[0].url}
                alt="profilePic"
                className="img-fluid"
              />
            </div>
            <div className="col-md-5">
              <h4
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#807373",
                }}
              >
                {auction?.artwork?.title}
              </h4>
              <p className="pb-0 cardText">
                by {auction?.artwork?.user.username}
              </p>
              <p className="cardText">{auction?.artwork?.description}</p>
              <div className="d-flex">
                {status === "past" &&
                  ["artistgod", "artistgod"].map((auctioneer, i) => {
                    return (
                      <div className="centerItems mr-2" key={i}>
                        <img
                          src="/images/profile.png"
                          alt="profile"
                          style={{ width: "20px", borderRadius: "50%" }}
                        />
                        <div className="ml-1 cardText">{auctioneer}</div>,
                      </div>
                    );
                  })}
              </div>
              {getDateText(auction?.starts)}
              {getViewBtn(auction)}
            </div>
            {status === "requests" ? (
              <div className="col-md-3 d-flex flex-md-column">
                <CustomButton
                  className="sendEmailBtn px-md-3 py-2 my-2 "
                  label="Approve"
                  type="button"
                  action={() => handleAction("approve", auction?.id)}
                />
                <CustomButton
                  className="deactivateBtn px-md-3 py-2 my-2 ml-3 ml-md-auto"
                  label="Decline"
                  type="button"
                  action={() => handleAction("decline", auction?.id)}
                />
              </div>
            ) : (
              <div className="col-md-3 centerItems">
                <CustomButton
                  className="deactivateBtn px-md-3 py-2"
                  label={status === "ongoing" ? "End Auction" : "Delete"}
                  type="button"
                  action={
                    status === "ongoing"
                      ? () => handleAction("end-auction", auction?.id)
                      : () => handleAction("delete", auction?.id)
                  }
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <div
          className="text-center p-3"
          style={{ borderBottom: "0.6px solid #C4C4C4" }}
        >
          <strong>No Auction Found!</strong>
        </div>
      )}
    </div>
  );
};

export default AuctionsCard;
