import React, { useEffect } from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../../store";
import { getDashboardData } from "../actions";
import {
  getCompletedTransactionsData,
  getFailedTransactionsData,
  getPendingTransactionsData,
} from "../../transactions/actions";
import Chart from "react-apexcharts";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    auth: { user },
    dashboardData: { users, artworks },
  } = useSelector((store: InitialState) => store);

  const { completedTransactions, pendingTransactions, failedTransactions } =
    useSelector((store: InitialState) => store.transactions);

  const cardContents = [
    {
      title: "TOTAL NO. OF USERS",
      value: users.total,
    },
    {
      title: "QRATERS",
      value: users.qraters,
    },
    {
      title: "ARTISTS",
      value: users.artists,
    },
    {
      title: "ART GALLERIES",
      value: users.galleries,
    },
    {
      title: "ART SUPPLIERS",
      value: users.supplies,
    },
  ];

  // Bar
  const barOptions = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: ["Qraters", "Artists", "Art Gallery", "Art Supplier"],
    },
    colors: ["#F7931E"],
  };
  const series = [
    {
      name: "series-1",
      data: [users.qraters, users.artists, users.galleries, users.supplies],
    },
  ];

  // Line
  const lineOptions = {
    chart: {
      stroke: {
        curve: "stepline",
      },
      id: "basic-bar",
    },
    colors: ["#F7931E"],
    // stroke: {
    //   curve: 'stepline',
    // },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    },
  };
  const lineSeries = [
    {
      name: "series-1",
      data: [900, 250, 750, 150, 850, 1600, 1000],
    },
  ];

  // pie
  const pieOptions = {
    labels: artworks.percentage_per_category.map((art: any) => art.name),
    pieSeries: artworks.percentage_per_category.map((art: any) =>
      Number(parseFloat(art.percentage).toFixed(1))
    ),
    dataLabels: {
      enabled: false,
    },
    colors: artworks.percentage_per_category.map((art: any) => art.color),
    fill: {
      colors: artworks.percentage_per_category.map((art: any) => art.color),
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            height: 80,
            offsetX: 25,
          },
        },
      },
    ],
  };

  const pieOptions2 = {
    labels: ["Completed", "Pending", "failed"],
    pieSeries2: [
      completedTransactions.length,
      pendingTransactions.length,
      failedTransactions.length,
    ],
    colors: ["#00C16E", "#FFB74F", "#FF0404"],
    fill: {
      colors: ["#00C16E", "#FFB74F", "#FF0404"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getCompletedTransactionsData());
    dispatch(getPendingTransactionsData());
    dispatch(getFailedTransactionsData());
    // return () => {
    //   cleanup
    // }
  }, [dispatch]);

  const renderDashboard = () => {
    return (
      <div className="o-dashboard container-fluid">
        <div className="row p-md-3 py-md-3">
          {cardContents.map(({ title, value }, i: number) => {
            return (
              <div className="col-sm-6 col-xl p-md-3 my-2 my-md-0" key={i}>
                <div className="dashboardCard m-card p-3 h-100">
                  <h6>{title}</h6>
                  <h2 className="--yellowText">{value}</h2>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row p-md-3">
          <div className="col-lg-6 p-md-3 my-2 my-md-0">
            <div className="dashboardCard m-card p-md-3 my-2 my-md-0 h-100">
              <h6 className="m-3">USER DISTRIBUTION</h6>
              <Chart
                options={barOptions}
                series={series}
                type="bar"
                // width="500"
              />
            </div>
          </div>
          <div className="col-lg-6 p-md-3 my-2 my-md-0">
            <div className="dashboardCard m-card p-md-3 my-2 my-md-0 h-100">
              <h6 className="m-3 mb-2">SITE ACTIVITY</h6>
              <Chart
                options={lineOptions}
                series={lineSeries}
                type="line"
                // width="500"
              />
            </div>
          </div>
        </div>

        <div className="row p-md-3">
          <div className="col-lg-12 my-2 my-lg-0">
            <div className="dashboardCard m-card p-md-3 h-100 donutOne ">
              <h6 className="m-3 mb-md-4">ART CATEGORIES</h6>
              <div className="chart">
                <Chart
                  options={pieOptions}
                  series={pieOptions.pieSeries}
                  type="donut"
                  // width="450"
                  labels={pieOptions.labels}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-2 my-lg-0">
            <div className="dashboardCard m-card p-md-3 h-100 mt-4 donutTwo">
              <h6 className="m-3 mb-md-4">TRANSACTIONS</h6>
              <div className="chart">
                <Chart
                  options={pieOptions2}
                  series={pieOptions2.pieSeries2}
                  type="donut"
                  // width="450"
                  labels={pieOptions2.labels}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderDashboard()}</DashboardLayout>;
};

export default Dashboard;
