import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { appNavItems } from "./constants/appNavItems";
import { useHistory, Link } from "react-router-dom";
import { Prop } from "../../types/prop.type";
import "./styles.css";

function HideOnScroll(props: Prop) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function NavTop() {
  const scrollTriggered = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const history = useHistory();
  const navBgColor = "transparent";

  const isActive = (navPath: string) => {
    return navPath === history.location.pathname ? "active" : "";
  };

  const [state, setState] = useState({
    drawer: false,
    navBgColor,
  });

  const toggleDrawer = (drawerState: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, drawer: drawerState });
  };
  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="right"
          open={state.drawer}
          onOpen={() => console.log("opened")}
          onClose={toggleDrawer(false)}
        >
          <div className="d-flex flex-column">
            <List>
              Don’t have an account? <Link to="signup">Create an account</Link>
            </List>
          </div>
        </SwipeableDrawer>
        <HideOnScroll>
          <AppBar className="o-topNav py-3 px-2 px-md-5 d-flex flex-row text-secondary">
            <img src="/logo.png" className="img-fluid" alt="logo" />
            <div className="d-md-flex d-none ml-auto">
              <div>
                <List className="d-flex">
                  {history.location.pathname.includes("login") ? (
                    <>
                      Don’t have an account? &nbsp;
                      <Link to="signup">Create an account</Link>
                    </>
                  ) : (
                    <>
                      Already have an account? &nbsp;
                      <Link to="login">Sign in</Link>
                    </>
                  )}
                </List>
              </div>
            </div>
            <Button
              onClick={toggleDrawer(true)}
              style={{ width: "fit-content" }}
              className="d-md-none ml-auto"
            >
              <MenuIcon style={{ color: scrollTriggered ? "" : "white" }}>
                drawer
              </MenuIcon>
            </Button>
          </AppBar>
        </HideOnScroll>
      </React.Fragment>
    </div>
  );
}
