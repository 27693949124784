import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";

export const getAuctionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/auctions");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_AUCTION_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getAuctionRequestsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/auctions/requests");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_REQUEST_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load requests failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getAuctionOngoingData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/auctions/live");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_ONGOING_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load requests failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getAuctionUpcomingData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/auctions/upcoming");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_UPCOMING_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load requests failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getAuctionPastData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/auctions/past");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_PAST_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load requests failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const approveAuction = (auctionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(
      dispatch,
      "get",
      `/auctionApproved/${auctionId}`
    );

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Approve auction failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const endAuction = (auctionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", `/endAuction/${auctionId}`);

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "End auction failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const deleteAuction = (auctionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });
  console.log("here");
  try {
    const res = await customAxios(
      dispatch,
      "get",
      `/auctionDestroy/${auctionId}`
    );

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_AUCTION_DATA",
        payload: { dispatchedAction: "delete", id: auctionId },
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Delete auction failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const declineAuction = (auctionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_AUCTION_DATA" });

  try {
    const res = await customAxios(
      dispatch,
      "get",
      `/auctionDecline/${auctionId}`
    );

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_AUCTION_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Decline auction failed",
      });
    }
  } catch (error: any) {
    console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};
