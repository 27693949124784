import React, { createContext, useState, useContext } from "react";

const initialState: any = {};
export const SelectedRowsContext = createContext(initialState);

export default function SelectedRowsContextProvider({ children }: any) {
  const [selectedRows, setSelectedRows] = useState([]);
  
  return (
    <SelectedRowsContext.Provider
      value={{selectedRows, setSelectedRows}}
    >
      {children}
    </SelectedRowsContext.Provider>
  );
}

export function useSelectedRowsContext() {
  return useContext(SelectedRowsContext);
}
