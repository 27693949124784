import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useHistory } from "react-router-dom";
import ArtistQraterLayout from "./ArtistQraterLayout";
import GalleryLayout from "./GalleryLayout";
import DetailLayout from "../../layout/DetailLayout";
import CustomButton from "../../input/Button";
import { useDispatch } from "react-redux";

function DatabaseDetail() {
  // Hooks
  const history: any = useHistory();
  const dispatch: any = useDispatch();

  // State management
  const { user } = history.location.state
    ? history.location.state
    : { user: null };
  console.log(user);

  const icons: any = {
    artist: "paint-palette.svg",
    qrater: "user.svg",
    gallery: "art-gallery.svg",
  };

  // user.role.name =
  //   user.role.name === "gallery"
  //     ? "Art Gallery"
  //     : user.role.name === "artist"
  //     ? "Artist"
  //     : "Qrater";

  // Handler functions
  const actionOnUserHandler = (action: "activate" | "deactivate") => {
    if (action === "activate") {

    } else {
      
    }
  }

  const renderDatabaseDetail = () => {
    return (
      <div className="row">
        <div className="" style={{ width: "fit-content" }}>
          <img
            src={user.profile_picture.thumbnail_url}
            alt="profile"
            style={{ width: "50px", borderRadius: "50%" }}
          />
        </div>
        <div className="col">
          <div className="row py-3">
            <div className="col-sm-8">
              {user.username} <br />
              <img
                src={`/icons/${icons[user.role.name] || "user.svg"}`}
                alt="profile"
                className="mr-3"
                style={{ width: "24px" }}
              />
              {user.role.name}
            </div>
            <div className="col-sm-4 d-flex">
              <a
                className="sendEmailBtn px-1 py-2 my-auto mr-2"
                href={`mailto:${user.email}`}
              >
                Send Email
              </a>
              {user.active ? (
                <CustomButton
                  className="deactivateBtn px-1 py-2 my-auto"
                  label="Deactivate"
                  type="button"
                  disableElevation
                  action={() => actionOnUserHandler("deactivate")}
                />
              ) : (
                <CustomButton
                  className="sendEmailBtn px-1 py-2 my-auto"
                  label="Activate"
                  type="button"
                  disableElevation
                  action={() => actionOnUserHandler("activate")}
                />
              )}
            </div>
          </div>

          {user.role.name === "gallery" ? (
            <GalleryLayout />
          ) : (
            <ArtistQraterLayout />
          )}
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DetailLayout>{renderDatabaseDetail()}</DetailLayout>
    </DashboardLayout>
  );
}

export default DatabaseDetail;
