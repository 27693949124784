import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import AuctionsTab from "./sub-components/AuctionsTab";
import CustomButton from "../../input/Button";
import { useHistory } from "react-router-dom";

function Auctions() {
  // History
  const history: any = useHistory();

  const renderAuctions = () => {
    return (
      <div className="o-auction">
        <div className="d-flex">
          <CustomButton
            className="createBtn px-3 py-2 my-auto ml-auto mr-4"
            label="Create Auction"
            type="button"
            disableElevation
            action={() => history.push("/auctions/create")}
          />
        </div>
        <AuctionsTab />
      </div>
    );
  };

  return <DashboardLayout>{renderAuctions()}</DashboardLayout>;
}

export default Auctions;
