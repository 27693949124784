import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";
import { returnErrors } from "../error/actions";

export type userData = {
  email: string,
  subscription: string,
}

/**
 * Triggers request to get all users
 *
 * @function
 * @return {Object} The {@link "GET_USERS" GET_USERS} action.
 */
export const getUsers = (): object => async (dispatch: any): Promise<any> => {
  try {
    dispatch({ type: "USERS_LOADING" });

    const res = await customAxios(dispatch, "get", "/users");
    console.log(res);

    // if (res.data && res.status === StatusCodes.OK) {
    //   await dispatch({
    //     type: "USERS_LOADED",
    //     payload: res.data
    //   });
    // }
  } catch (error: any) {
    console.log(error.message);
    await dispatch({
      type: "USERS_LOADING_FAILED"
    });
  }
} 