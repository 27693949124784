import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import TransactionsTab from "./sub-components/TransactionsTab";

function Transactions() {
  const renderTransactions = () => {
    return (
      <div className="o-dashboard">
        <div className="container">
          <TransactionsTab />
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderTransactions()}</DashboardLayout>;
}

export default Transactions;
