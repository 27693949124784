import React, { useState } from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import CustomButton from "../../input/Button";
import { useHistory } from "react-router-dom";
import repeater from "../../utils/repeater";
import { Add } from "@material-ui/icons";
import AuctionsCreateForm from "./AuctionsCreateForm";

function AuctionsCreate() {
  // State management
  const [artworkCount, setArtworkCount] = useState(0);
  // History
  // const history: any = useHistory();

  const renderAuctionsCreate = () => {
    return (
      <div className="o-auction">
        <div className="px-md-5">
          {artworkCount !== 0 &&
            repeater(
              <div>
                <h5>ARTWORK 1</h5>
                <AuctionsCreateForm />
              </div>,
              artworkCount
            )}

          <div className="">
            <span
              className="addArtwork d-flex align-items-center"
              onClick={() => setArtworkCount(artworkCount + 1)}
            >
              <Add />
              Add Artwork
            </span>
          </div>

          <CustomButton
            className="createBtn px-3 py-2 my-4"
            label="Create Auction"
            type="button"
            disableElevation
            action={() => alert("no api yet")}
          />
        </div>
        <div
          className="container-fluid px-md-5 py-5"
          style={{ borderTop: "0.6px solid #C4C4C4" }}
        >
          <h5>Suggestions</h5>
          {[
            {
              title: "THE STORY OF THE EYES",
              description: "Glossed art print, 70 × 45 cm",
              imgSrc: "/images/pic1.png",
              date: "24 Sep. 2020, 2:05PM",
            },
            {
              title: "THE STORY OF THE EYES",
              description: "Glossed art print, 70 × 45 cm",
              imgSrc: "/images/pic1.png",
              date: "24 Sep. 2020, 2:05PM",
            },
            {
              title: "THE STORY OF THE EYES",
              description: "Glossed art print, 70 × 45 cm",
              imgSrc: "/images/pic1.png",
              date: "24 Sep. 2020, 2:05PM",
            },
            {
              title: "THE STORY OF THE EYES",
              description: "Glossed art print, 70 × 45 cm",
              imgSrc: "/images/pic1.png",
              date: "24 Sep. 2020, 2:05PM",
            },
          ].map(
            ({ title, imgSrc, author, description, date }: any, i: number) => (
              <div className="row p-3" key={i}>
                <div className="col-md-3">
                  <img src={imgSrc} alt="profilePic" className="img-fluid" />
                </div>
                <div className="col-md d-flex flex-column justify-content-center">
                  <h4
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#807373",
                    }}
                  >
                    {title}
                  </h4>
                  <p className="cardText">{description}</p>
                  <CustomButton
                    style={{ width: "fit-content" }}
                    className="sendEmailBtn px-5 py-2 my-2"
                    label="Add"
                    type="button"
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderAuctionsCreate()}</DashboardLayout>;
}

export default AuctionsCreate;
