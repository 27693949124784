import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";

function Settings() {
  const renderSettings = () => {
    return (
      <div className="o-dashboard">
        <div className="container">
          <h1>Settings</h1>
        </div>
      </div>
    )
  };

  return (<DashboardLayout >{ renderSettings() }</DashboardLayout>);
}

export default Settings;
