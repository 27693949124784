import React, { useState, useContext, useEffect } from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import CustomButton from "../../input/Button";
import { useHistory } from "react-router-dom";
import ExhibitionsTab from "./sub-components/ExhibitionsTab";
import { SelectedRowsContext } from "../../../contexts/SelectedRowsContext";
import { useDispatch } from "react-redux";
import { getExhibitionsData } from "../actions";

function Exhibitions() {
  // State management
  const [currentTab, setCurrentTab] = useState(0);

  const { selectedRows } = useContext(SelectedRowsContext)
  // console.log(selectedRows)
  // History
  const history: any = useHistory();
  // Dispatch
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(getExhibitionsData());
  }, []);

  //
  const tabState: any = {
    0: "Requests",
    1: "Live",
    2: "Upcoming",
    3: "Past",
  };

  const renderExhibitions = () => {
    return (
      // <SelectedRowsContextProvider>
          <div className="o-auction">
            <div className="d-flex mb-4">
              <CustomButton
                className="createBtn px-3 py-2 my-auto ml-auto mr-4"
                label="Create Exhibition"
                type="button"
                disableElevation
                action={() => history.push("/exhibitions/create")}
              />
            </div>
            <div className="cardBgImg mb-5">
              <img
                className="object-cover"
                style={{ opacity: 1 }}
                src="images/map.png"
                alt={"map"}
              />
              <section className="flex flex-col w-full h-full"></section>
            </div>
            {selectedRows && selectedRows.length > 0 && (
              <div className="px-4">
                {tabState[currentTab] === "Requests" ? (
                  <div className="d-flex">
                    <CustomButton
                      className="sendEmailBtn px-3 py-2 my-auto ml-auto mr-4"
                      label="Approve"
                      type="button"
                      disableElevation
                      action={() => ""}
                    />
                    <CustomButton
                      className="deactivateBtn px-3 py-2 my-auto"
                      label="Decline"
                      type="button"
                      disableElevation
                      action={() => ""}
                    />
                  </div>
                ) : tabState[currentTab] === "Live" ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <CustomButton
                      className="deactivateBtn px-3 py-2 my-auto ml-auto"
                      label="Delete"
                      type="button"
                      disableElevation
                      action={() => ""}
                    />
                  </div>
                )}
              </div>
            )}
            <ExhibitionsTab
              value={currentTab}
              setValue={setCurrentTab}
              data={[
                {
                  _id: 1,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "requests",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
                {
                  _id: 6,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "requests",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
                {
                  _id: 2,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "live",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
                {
                  _id: 5,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "live",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
                {
                  _id: 3,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "upcoming",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
                {
                  _id: 4,
                  title: "Art De Supreme 2020",
                  viewers: "13,458",
                  location: "Nike Art Gallery, Lekki, Lagos",
                  status: "past",
                  imgSrc: "/images/exhibition-banner.png",
                  date: "24 Sep. 2020, 2:05PM",
                },
              ]}
            />
          </div>
      // </SelectedRowsContextProvider>
    );
  };

  return <DashboardLayout>{renderExhibitions()}</DashboardLayout>;
}

export default Exhibitions;
