import React from "react";
import "./styles.css";
import CustomButton from "../../input/Button";

export default function ArtistQraterLayout() {
  return (
    <>
      <p>
        Jackson Pallock is implicitly synonymous with modern art, and it doesn’t
        hurt that he fits the generally held image of the fugitive genius whose
        goals are balanced by a taste for living big. He turned the field of art
        history with radical innovations that include college and Cubism, which
        destroyed the stranglehold of representational material matter on art,
        and set the rate for other 20th-century artists.
      </p>

      <p>
        <strong>Category of works: </strong>
        {["Acrylic Painting", "Oil painting", "Sculpture"].map(
          (category: string, i: number) => {
            return (
              <span className="mx-3" key={i}>
                {category}
              </span>
            );
          }
        )}
      </p>

      {[
        {
          title: "THE STORY OF THE EYES",
          details: "Glossed art print, 70 × 45 cm",
          bid: "$ 150",
          img: "/images/pic1.png",
        },
        {
          title: "THE STORY OF THE EYES",
          details: "Glossed art print, 70 × 45 cm",
          bid: "Highest Bidder: $ 150",
          img: "/images/pic1.png",
        },
      ].map((work, i: number) => {
        return (
          <div className="row py-3">
            <div className="col-sm-4">
              <img src={work.img} alt="work" className="img-fluid" />
            </div>
            <div className="col-sm-8">
              <h5>{work.title}</h5>
              <p>{work.details}</p>
              <h6>{work.bid}</h6>
              <CustomButton
                className="--yellowBg text-white px-4"
                label="View"
                type="button"
                // action={}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}
