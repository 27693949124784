import React, { useState, createContext } from "react";
import MaterialTable from "material-table";
import { Prop } from "../../../../types/prop.type";
import { Icon } from "@material-ui/core";
import "../styles.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { InitialState } from "../../../../store";

const DatabaseTable = () => {
  // State management
  const { users } = useSelector((store: InitialState) => store.database);
  const history = useHistory();
  // const [open, setOpen] = useState(false);
  // // const ActionModalContext = createContext({ open, setOpen });
  // const [userData, setUserData] = useState({});
  // const { action } = prop;
  console.log(users);

  const goToUser = (user: any) => {
    // setOpen(true);
    console.log(user);
    history.push("/database/detail", { user });
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        options={{
          emptyRowsWhenPaging: false,
          selection: true,
        }}
        actions={[
          {
            tooltip: "Remove All Selected Users",
            icon: "delete",
            onClick: (evt, data: any) => {
              console.log(data);
              alert("You want to delete " + data.length + " rows");
            },
          },
        ]}
        style={{
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
        page={10}
        columns={[
          {
            field: "username",
            title: "Username",
            render: (rowData: any) => {
              return (
                <div className="--pointer" onClick={() => goToUser(rowData)}>
                  {rowData.username}
                </div>
              );
            },
          },
          {
            field: "email",
            title: "Email address",
            render: (rowData: any) => {
              return (
                <div className="--pointer" onClick={() => goToUser(rowData)}>
                  {rowData.email}
                </div>
              );
            },
          },
          {
            field: "role",
            title: "Designation",
            render: (rowData: any) => {
              return (
                <div className="--pointer" onClick={() => goToUser(rowData)}>
                  {rowData.role.name}
                </div>
              );
            },
          },
          {
            field: "categories",
            title: "Categories",
            render: (rowData: any) => {
              return (
                <div className="--pointer" onClick={() => goToUser(rowData)}>
                  {rowData.categories}
                </div>
              );
            },
          },
        ]}
        data={users}
        title=""
      />

      {/* <ActionModalContext.Consumer>
        {(state: { open: boolean; setOpen: any }) => (
          <ActionsModal open={state.open} setOpen={state.setOpen}>
            <ListItem
              button
              className="text-center actionTab"
              onClick={() => action("view", userData)}
            >
              <ListItemText className="ml-2" primary="View user’s order history" />
            </ListItem>
            <ListItem
              button
              className="text-center actionTab"
              onClick={() => action("change-plan", userData)}
            >
              <Icon>edit</Icon>
              <ListItemText className="ml-2" primary="Change user’s plan" />
            </ListItem>
            <ListItem
              button
              className="text-center actionTab"
              onClick={() => action("delete", userData)}
            >
              <Icon className="text-danger">delete</Icon>
              <ListItemText className="ml-2 text-danger" primary="Remove user" />
            </ListItem>
            <ListItem
              button
              className="text-center "
              onClick={() => setOpen(false)}
            >
              <ListItemText className="ml-2" primary="Cancel" />
            </ListItem>

          </ActionsModal>
        )}
      </ActionModalContext.Consumer> */}
    </div>
  );
};

export default DatabaseTable;
