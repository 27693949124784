import React, { useState } from "react";
import "./styles.css";
import CustomButton from "../../input/Button";
import DashboardLayout from "../../layout/DashboardLayout";
// import { useHistory } from "react-router-dom";
// import repeater from "../../utils/repeater";
// import { Add } from "@material-ui/icons";
import ExhibitionsCreateForm from "./ExhibitionsCreateForm";

function ExhibitionsCreate() {
  // State management
  // const [artworkCount, setArtworkCount] = useState(0);
  // // History
  // const history: any = useHistory();

  const renderExhibitionsCreate = () => {
    return (
      <div className="o-Exhbition">
        <div className="px-md-5">
          
        <ExhibitionsCreateForm />
{/* 
          <div className="">
            <span
              className="addArtwork d-flex align-items-center"
              onClick={() => setArtworkCount(artworkCount + 1)}
            >
              <Add />
              Add Artwork
            </span>
          </div> */}

          <CustomButton
            className="createBtn px-3 py-2 my-4"
            label="Create Exhbition"
            type="button"
            disableElevation
            action={() => alert("create")}
          />
        </div>
       </div>
    );
  };

  return <DashboardLayout>{renderExhibitionsCreate()}</DashboardLayout>;
}

export default ExhibitionsCreate;
