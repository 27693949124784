export const dashboardNavItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "dashboard",
  },
  {
    title: "Database",
    path: "/database",
    icon: "database",
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: "transaction",
  },
  {
    title: "Auctions",
    path: "/auctions",
    icon: "auction",
  },
  {
    title: "Exhibitions",
    path: "/exhibitions",
    icon: "exhibition",
  },
];
