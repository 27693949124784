const initialState = {
  isLoading: false,
  users: []
};

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "USERS_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "USERS_LOADING_FAILED":
      return {
        ...state,
        isLoading: false
      }
    case "USERS_LOADED":
      console.log(action.payload)

      return {
        ...state,
        isLoading: false,
        users: action.payload
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}