const initialState = {
  isLoading: false,
  transactions: [],
  completedTransactions: [],
  pendingTransactions: [],
  failedTransactions: [],
}

export const transactionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GETTING_TRANSACTION_DATA":
      return {
        ...state,
        isLoading: true
      }
    case "GET_TRANSACTION_DATA":
      return {
        ...state,
        isLoading: false,
        transactions: action.payload.data
      }
    case "GET_COMPLETED_TRANSACTION_DATA":
      return {
        ...state,
        isLoading: false,
        completedTransactions: action.payload.data
      }
    case "GET_PENDING_TRANSACTION_DATA":
      return {
        ...state,
        isLoading: false,
        pendingTransactions: action.payload.data
      }
    case "GET_FAILED_TRANSACTION_DATA":
      return {
        ...state,
        isLoading: false,
        failedTransactions: action.payload.data
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}