import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';  

const useStyles = makeStyles((theme) => ({
  root: {
    height: '300px',
    minWidth: '100%'
  },
  details: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: 0
  },
  title: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6))',
    backdropFilter: 'blur(100px)',
    // borderRadius: '0px 0px 10px 10px',
    width: '100%',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }
}));

const AuctionCardCarousel = ({ img }: any) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content + ' carousel-content'}
          style={{ background: `url(${img}) center no-repeat`, backgroundColor: '#FCFCFC'}}>
        </CardContent>
      </div>
    </Card>
  );
}

export default AuctionCardCarousel;
