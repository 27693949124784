import { Storage } from "../utils/storage";
import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";
import { user } from "../../types/user.type";

export const logUserIn = (user: user) => async (dispatch: any) => {
  dispatch({ type: "USER_LOADING" });

  try {
    const res = await customAxios(dispatch, "post", "/login", user);

    if (
      res.status === StatusCodes.OK &&
      !res.data.message.includes("Invalid Credentials")
    ) {
      if (res.data.success) {
        await dispatch({
          type: "LOGIN_SUCCESS",
          payload: res.data,
        });
      } else {
        await dispatch({
          type: "LOGIN_FAIL",
        });
      }
    } else {
      await dispatch({
        type: "LOGIN_FAIL",
      });
    }
    return res;
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOGIN_FAIL",
      payload: error.response.data,
    });
  }
};

export const registerUser = (user: user) => async (dispatch: any) => {
  dispatch({ type: "USER_LOADING" });

  try {
    const res = await customAxios(dispatch, "post", "/signup", user);
    // console.log(user, res)

    if (res.status === StatusCodes.OK) {
      if (res.data.message !== "Invalid Data") {
        await dispatch({
          type: "REGISTER_SUCCESS",
          payload: res.data,
        });
      } else {
        await dispatch({
          type: "REGISTER_FAIL",
        });
      }
    } else {
      await dispatch({
        type: "REGISTER_FAIL",
      });
    }
    return res;
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "REGISTER_FAIL",
      payload: error.response.data,
    });
  }
};

export const loadUser = () => async (dispatch: any, getState: any) => {
  dispatch({ type: "USER_LOADING" });
  // console.log("getState", getState());

  try {
    // console.log('storage', Storage.getItem('qrate-admin'))
    const response =
      Storage.getItem("qrate-admin") === undefined
        ? { data: null, success: false }
        : Storage.getItem("qrate-admin");

    // console.log('authActions response', response);

    if (response && response.access_token) {
      await dispatch({
        type: "USER_LOADED",
        payload: { user: response, isAuthenticated: true },
      });
    } else {
      await dispatch({
        type: "AUTH_ERROR",
        payload: { user: response, isAuthenticated: false },
      });
    }
  } catch (error: any) {
    // console.error('error', error);
    // await dispatch(returnErrors(error.response.data, error.response.status));
    await dispatch({
      type: "AUTH_ERROR",
    });
  }
};

export const logUserOut = () => async (dispatch: any) => {
  dispatch({ type: "USER_LOADING" });

  try {
    const res = await customAxios(dispatch, "post", "/logout");

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "LOGOUT_SUCCESS",
        payload: res.data,
      });
    } else {
      await dispatch({
        type: "LOGOUT_FAIL",
      });
    }
    return res;
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOGOUT_FAIL",
      payload: error.response.data,
    });
  }
};
