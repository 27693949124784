import { Storage } from "../utils/storage";

const user = Storage.getItem("qrate-admin");

// console.log(user)
const initialState = {
  isAuthenticated: user && user.access_token ? true : false,
  isLoading: false,
  user: user && user.success ? user : null,
  token: user ? user.access_token : null,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "USER_LOADED":
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "LOGIN_SUCCESS":
    case "REGISTER_SUCCESS":
      Storage.setItem("qrate-admin", action.payload);
      console.log("logged in", action.payload);
      return {
        ...state,
        user: action.payload,
        token: action.payload.access_token,
        isAuthenticated: action.payload.success === true || false,
        isLoading: false,
      };
    case "LOGIN_FAIL":
    case "LOGOUT_SUCCESS":
    case "LOGOUT_FAIL":
    case "AUTH_ERROR":
    case "REGISTER_FAIL":
      Storage.removeItem("qrate-admin");
      console.log("logged out!");
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};
