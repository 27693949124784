interface InitialState {
  isLoading: boolean,
  auctions: any[],
  requestAuctions: any[],
  ongoingAuctions: any[],
  upcomingAuctions: any[],
  pastAuctions: any[]
}

const initialState: InitialState = {
  isLoading: false,
  auctions: [],
  requestAuctions: [],
  ongoingAuctions: [],
  upcomingAuctions: [],
  pastAuctions: []
}

export const auctionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GETTING_AUCTION_DATA":
      return {
        ...state,
        isLoading: true
      }
    case "GET_AUCTION_DATA":
      return {
        ...state,
        isLoading: false,
        auctions: action.payload
      }
    case "GET_REQUEST_AUCTION_DATA":
      return {
        ...state,
        isLoading: false,
        requestAuctions: action.payload
      }
    case "GET_ONGOING_AUCTION_DATA":
      return {
        ...state,
        isLoading: false,
        ongoingAuctions: action.payload
      }
    case "GET_UPCOMING_AUCTION_DATA":
      return {
        ...state,
        isLoading: false,
        upcomingAuctions: action.payload
      }
    case "GET_PAST_AUCTION_DATA":
      return {
        ...state,
        isLoading: false,
        pastAuctions: action.payload
      }
    case "UPDATE_AUCTION_DATA":
      const { dispatchedAction, id } : { dispatchedAction: string, id: number } = action.payload;
      switch (dispatchedAction) {
        case "delete":
          state.pastAuctions = state.pastAuctions.filter(auction => auction.id !== id);
          break;
        default:
          break;
      }
      return {
        ...state,
        isLoading: false
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}