import React, { useState } from "react";
import { FormControl } from "@material-ui/core";

const ExhibitionsCreateForm = () => {
  const [file, setFile] = useState(null);
  // Handler functions
  const handleFile = (e: any) => setFile(e.target.files[0]);
  // const handleFile = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files[0])

  return (
    <div className="mt-3 mb-5">
      <div className="--pointer">
        <div className="container-fluid">
          <div className="row">
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="theme">
                <h6 className="my-2">Theme of Exhbition</h6>
                <input type="text" id="theme" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-12">
              <label htmlFor="desc">
                <h6 className="my-2">Description</h6>
                <textarea id="desc" className="w-100" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="date">
                <h6 className="my-2">Date of event</h6>
                <input type="date" id="date" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pl-md-3">
              <label htmlFor="time">
                <h6 className="my-2">Time</h6>
                <input type="time" id="time" className="w-100 a-inputField" />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pr-md-3">
              <label htmlFor="price">
                <h6 className="my-2">Address</h6>
                <input
                  type="text"
                  id="price"
                  className="w-100 a-inputField"
                  placeholder="Standard Purchase"
                />
              </label>
            </FormControl>
            <FormControl className="col-md-6 pl-md-3">
              <label htmlFor="country">
                <h6 className="my-2">Country</h6>
                <select id="country" className="w-100 a-inputField">
                  <option value="Nigeria">Nigeria</option>
                </select>
              </label>
            </FormControl>
            <label
              title="Upload File"
              className="--pointer fileUpload flex-column py-5 my-2 centerItems w-100"
              htmlFor="fileUpload"
            >
              <img src="/icons/upload.svg" className="--pointer" alt="upload" />
              <span className="addArtwork">
                Upload images, videos of your exhibition
              </span>
              <input
                className="d-none"
                id="fileUpload"
                name="fileUpload"
                type="file"
                accept="image/*,video/*"
                onChange={handleFile}
              />
              {/* <span className="relative">
                    {file && (
                      <span className={styles.selectedFileIndicator}>1</span>
                    )}
                  </span> */}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionsCreateForm;
