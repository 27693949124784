import React, { useState } from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { Prop } from "../../types/prop.type";
import MenuIcon from "@material-ui/icons/Menu";
import {
  List,
  ListItem,
  ListItemText,
  Icon,
  SwipeableDrawer,
  Button,
} from "@material-ui/core";
import { dashboardNavItems } from "./constants/dashboardNavItems";

const NavSideDrawer = (prop: Prop) => {
  const history = useHistory();

  const handleClick = (path: string) => {
    history.push(path);
  };

  const activeNav = (path: string) => history.location.pathname.includes(path);

  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = (drawerState: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawer(drawerState);
  };

  return (
    <>
      <Button
        onClick={toggleDrawer(true)}
        style={{ width: "fit-content" }}
        className="d-md-none"
      >
        <MenuIcon>drawer</MenuIcon>
      </Button>
      <SwipeableDrawer
        PaperProps={{ style: { borderRadius: "0 11.2753px 11.2753px 0" } }}
        anchor="left"
        open={drawer}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        <div className="o-sideNav w-100">
          <List className="d-flex flex-column h-100">
            <div className="a-logoContainer centerItems px-3">
              <div className="">
                <img src="/logo.png" className="img-fluid px-4" alt="logo" />
                <hr style={{ border: "0.5px solid #CCCCCC" }} />
              </div>
            </div>
            {dashboardNavItems.map((nav, index) => {
              return (
                <ListItem
                  button
                  key={index}
                  className={activeNav(nav.path) ? "Mui-selected" : "a-navItem"}
                  onClick={() => handleClick(nav.path)}
                >
                  <img
                    src={"/icons/nav/" + nav.icon + ".svg"}
                    className="img-fluid"
                    alt="icon"
                  />
                  <ListItemText className="ml-3" primary={nav.title} />
                </ListItem>
              );
            })}

            <ListItem
              button
              className="a-navItem mt-auto"
              onClick={() => prop.logout()}
            >
              <img
                src={"/icons/nav/log_out.svg"}
                className="img-fluid"
                alt="icon"
              />
              <ListItemText className="ml-3" primary="Log Out" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default NavSideDrawer;
