import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import { InitialState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors } from "../error/actions";
import CustomButton from "../input/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      borderRadius: "15px",
    },
    largeIcon: {
      fontSize: "150px",
      color: "var(--red)",
    },
  })
);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Modal() {
  const error = useSelector((store: InitialState) => store.error);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = () => {
    dispatch(clearErrors());
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="dialog-box"
      open={error.show}
      classes={{
        paper: classes.root,
      }}
    >
      <DialogContent className="text-center">
        {error.success === null ? "" :
         error.success ? (
          <img className="img-fluid" src="/images/tick.png" alt="tick" />
        ) : (
          <ErrorOutlineIcon classes={{ root: classes.largeIcon }} />
          )}
        
        <Typography gutterBottom variant="h5">
          {error.title}
        </Typography>
        <Typography gutterBottom>{error.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          onClick={handleClose}
          className="mr-5"
        >
          Cancel
        </Button>
        <CustomButton
          className="--inviteButtonBg ml-auto"
          label="OK, GOT IT"
          type="button"
          action={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
}
