import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './reducers'

export type InitialState = {
  auth ?: any
  dashboardData ?: any
  database ?: any
  users ?: any
  auctions ?: any
  exhibitions ?: any
  transactions ?: any
  error ?: any
}

const initialState: InitialState = {}
const middleware = [thunkMiddleware];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
