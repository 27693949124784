import React from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { KeyboardBackspace } from "@material-ui/icons";
import { Prop } from "../../types/prop.type";
import "./styles.css";
import NavSideDrawer from "./NavSideDrawer";

function HideOnScroll(prop: Prop) {
  const { children } = prop;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(prop: Prop) {
  const history = useHistory();

  return (
    <div>
      <React.Fragment>
        <HideOnScroll>
          <AppBar
            className={
              prop.className +
              " py-3 px-3 pr-md-5 d-flex flex-row bg-white text-secondary"
            }
          >
            {/* <div className="o-header">
              {/* <div onClick={() => history.goBack()} className="--pointer">
                <KeyboardBackspace className="my-2 mx-1" /> Back
              </div> *}
              &nbsp;
            </div> */}
            <div className="ml-auto mr-2 centerItems">
              <img
                src="/icons/nav/clarity_notification-solid-badged.svg"
                className="img-fluid"
                width={20}
                alt="icon"
              />
              <div className="ml-3">Jahness Hamsen</div>
            </div>
            <div className="d-md-none">
              <NavSideDrawer logout={prop.logout} />
            </div>
          </AppBar>
        </HideOnScroll>
      </React.Fragment>
    </div>
  );
}
