import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import queryString from "querystring";
import "./styles.css";
import PasswordField from "../../input/PasswordField";
import InputField from "../../input/InputField";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../input/Button";
// import auth from "../../../constants";
import { customAxios } from "../../../constants/axios";
import { SnackBar } from "../../handlers/SnackBar";
import AppBackdrop from "../../sub components/Backdrop";
import { KeyboardBackspace } from "@material-ui/icons";
import AppLayout from "../../layout/AppLayout";
import { StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { registerUser } from "../actions";

function Signup() {
  const [values, setValues] = useState({
    Name: "",
    Email: "",
    Password: "",
    "Confirm Password": "",
    Location: "",
    showPassword: false,
  });

  const [state, setState] = useState({
    status: "",
    message: "",
    snackBarOpened: false,
    idToken: "",
    backdrop: false,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const renderSnackbar = (status: string, message: string) => {
    setState({ ...state, status, message, snackBarOpened: true });
  };

  const signUp = async (e: any) => {
    e.preventDefault();
    try {
      setState({ ...state, backdrop: true });
      // make login axios call
      const res: any = await dispatch(
        registerUser({
          name: values.Name,
          email: values.Email,
          password: values.Password,
        })
      );
      console.log(res);
      if (res.status === StatusCodes.ACCEPTED) {
        renderSnackbar("success", "Login successful.");

        // setTimeout(() => history.push("/dashboard"), 2000);
        // setTimeout(() => history.push("/login"), 2000);
      } else {
        renderSnackbar("error", "Account creation failed.");
      }
    } catch (error: any) {
      setState({ ...state, backdrop: false });

      if (error.response) {
        renderSnackbar(
          "error",
          error.response.data.message === "Network Error"
            ? "Network Error"
            : error.response.data.message
        );
      } else renderSnackbar("error", "Account creation failed.");
    }
  };

  const renderSignUp = () => {
    return (
      <div className="o-login m-centerContents mt-5">
        <div className="container col-sm-10 col-md-8 pt-0 pb-3 px-5 m-auto m-centerContents">
          <img src="/logo-black.png" className="img-fluid" alt="logo" />
          <header className="m-loginHeader">Create an account</header>

          <form onSubmit={signUp} className="o-loginForm m-centerContents">
            {["Name", "Email", "Password"].map((field, index) => {
              if (field === "Password") {
                return (
                  <FormControl
                    key={index}
                    style={{ width: "100%" }}
                    className="my-3"
                  >
                    <PasswordField
                      placeholder={field}
                      variant="outlined"
                      values={values}
                      field={field}
                      setValues={setValues}
                    />
                  </FormControl>
                );
              } else {
                return (
                  <FormControl
                    key={index}
                    style={{ width: "100%" }}
                    className="my-3"
                  >
                    <InputField
                      placeholder={field}
                      variant="outlined"
                      values={values}
                      field={field}
                      setValues={setValues}
                      required={true}
                      icon={field === "Email" ? "mail_outline_outlined" : ""}
                    />
                  </FormControl>
                );
              }
            })}
            <div className="my-3 ml-auto">
              <CustomButton
                className="loginBtn"
                label="Sign up"
                type="submit"
              />
            </div>
          </form>
        </div>

        <SnackBar
          status={state.status}
          message={state.message}
          state={state}
          setState={setState}
        />

        <AppBackdrop backdrop={state.backdrop} />
      </div>
    );
  };

  return <AppLayout>{renderSignUp()}</AppLayout>;
}

export default Signup;
