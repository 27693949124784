import { createContext } from 'react'
const any: any = "";
const UserContext = createContext(
  any
  // {
  // loggedInUser: {},
  // // setLoggedInUser: function(): Dispatch<SetStateAction<{}>> {}
  // }
)

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer

export default UserContext
