import React from 'react';
import { Prop } from '../../types/prop.type';
import "../layout/styles.css";
const { Backdrop, CircularProgress } = require("@material-ui/core");

const AppBackdrop = (prop: Prop) => {
  return (
    <Backdrop
      className="a-backdrop"
      open={prop.backdrop || false}
      // onClick={() => setState({ ...state, backdrop: false })}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default AppBackdrop;
