import React from "react";
import "./styles.css";
import Header from "./Header";
import { Prop } from "../../types/prop.type";
import NavSide from "./NavSide";
import { useHistory } from "react-router-dom";
import Modal from "../sub components/Modal";
// import ActionsModal from "../sub components/ActionsModal";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../store";
import AppBackdrop from "../sub components/Backdrop";
import NavSideDrawer from "./NavSideDrawer";
import { logUserOut } from "../login/actions";

const DashboardLayout = (prop: Prop) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const store = useSelector((store: InitialState) => store);
  const isLoading =
    store.auth.isLoading ||
    store.users.isLoading ||
    store.database.isLoading ||
    store.auctions.isLoading ||
    store.exhibitions.isLoading ||
    store.transactions.isLoading ||
    store.dashboardData.isLoading;

  // console.log(isLoading)

  const handleLogout = () => {
    try {
      dispatch(logUserOut());

      history.push("/login");
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <div className="o-layout">
      <div className="container-fluid px-md-0">
        <div className="d-flex">
          <div className="d-none d-md-block m-navSideContainer">
            <NavSide logout={handleLogout} />
          </div>
          <main className="m-main px-0 ml-auto">
            <Header className="m-main" title="" logout={handleLogout} />
            <div className="m-mainBodyContainer">{prop.children}</div>
          </main>
        </div>
      </div>

      <Modal />

      <AppBackdrop backdrop={isLoading} />

      {/* <ActionsModal /> */}
    </div>
  );
};

export default DashboardLayout;
