import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";

export const getExhibitionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/exhibitions");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_EXHIBITION_DATA",
        payload: res.data.resource
      })
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Load resource failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const getExhibitionRequestsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/exhibitions/request");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_REQUEST_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Load requests failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const getExhibitionLiveData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/exhibitions/live");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_LIVE_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Load requests failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const getExhibitionUpcomingData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/exhibitions/upcoming");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_UPCOMING_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Load requests failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const getExhibitionPastData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/exhibitions/past");

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_PAST_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Load requests failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const approveExhibition = (exhibitionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", `/exhibitionApproved/${exhibitionId}`);

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Approve exhibition failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const endExhibition = (exhibitionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", `/endExhibition/${exhibitionId}`);

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "End exhibition failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const deleteExhibition = (exhibitionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });
console.log("here")
  try {
    const res = await customAxios(dispatch, "get", `/exhibitionDestroy/${exhibitionId}`);

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_EXHIBITION_DATA",
        payload: {dispatchedAction: "delete", id: exhibitionId}
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Delete exhibition failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}

export const declineExhibition = (exhibitionId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_EXHIBITION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", `/exhibitionDecline/${exhibitionId}`);

    if (res && res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_EXHIBITION_DATA",
        payload: res.data.resource
      })

      return res.data.resource;
    } else {
      await dispatch({
        type: 'LOAD_ERROR',
        payload: "Decline exhibition failed"
      });
    }
  } catch (error: any) {
    console.error('error', error.message, error.response.status);

    await dispatch({
      type: 'LOAD_ERROR',
      payload: error.response.data
    });
  }
}