import React, { useState, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import PasswordField from "../../input/PasswordField";
import InputField from "../../input/InputField";
import "./styles.css";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../input/Button";
import { customAxios } from "../../../constants/axios";
import { responseObject } from "../../../types/response.object.type";
import { SnackBar } from "../../handlers/SnackBar";
import AppBackdrop from "../../sub components/Backdrop";
import statusCodes from "http-status-codes";
import { useDispatch } from "react-redux";
import AppLayout from "../../layout/AppLayout";
import { logUserIn } from "../actions";

function Login() {
  const [values, setValues] = useState({
    Email: "",
    Password: "",
    showPassword: false,
  });

  const [state, setState] = useState({
    status: "",
    message: "",
    snackBarOpened: false,
    backdrop: false,
  });

  // const { userLogin } = useContext(LoginContext);

  const renderSnackbar = (status: string, message: string) => {
    setState({ backdrop: false, status, message, snackBarOpened: true });
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const login = async (e: any) => {
    e.preventDefault();

    try {
      setState({ ...state, backdrop: true });      
      // make login axios call
      const res:any = await dispatch(logUserIn({
        email: values.Email,
        password: values.Password
      }));
      if (res.status === statusCodes.OK && !res.data.message.includes("Invalid Credentials")) {
        renderSnackbar("success", "Login successful.");

        setTimeout(() => history.push("/dashboard"), 2000);
      } else {
        renderSnackbar("error", res.data?.message ? res.data.message : "Invalid user credentials.");
      }
    } catch (error: any) {
      console.log(error.message);
      if (
        error.message === "Network Error"
        || error.message === "A network error (such as timeout, interrupted connection or unreachable host) has occurred."
      ) {
        renderSnackbar("error", "Network Error");
      } else if (error.response) {
        renderSnackbar("error", error.response.data.message);
      } else renderSnackbar("error", "Invalid user credentials.");
    }
  };

  const renderLogin = () => {
    return (
      <div className="o-login m-centerContents mt-4">
        <div className="container col-sm-10 col-md-8 pt-0 pb-3 px-5 m-auto m-centerContents">
          <img src="/logo-black.png" className="img-fluid" alt="logo"/>
          <header className="m-loginHeader">
          Login to the dashboard with the form below
          </header>

          <form onSubmit={login} className="o-loginForm m-centerContents">
            {["Email", "Password"].map((field, index) => {
              if (field === "Password") {
                return (
                  <FormControl
                    key={index}
                    style={{ width: "100%" }}
                    className="my-3"
                  >
                    <PasswordField
                      placeholder={field}
                      variant="outlined"
                      values={values}
                      field={field}
                      setValues={setValues}
                    />
                  </FormControl>
                );
              } else {
                return (
                  <FormControl
                    key={index}
                    style={{ width: "100%" }}
                    className="my-3"
                  >
                    <InputField
                      placeholder={field}
                      variant="outlined"
                      values={values}
                      field={field}
                      setValues={setValues}
                      required={true}
                      icon="mail_outline_outlined"
                    />
                  </FormControl>
                );
              }
            })}
            <div className="my-3 ml-auto">
              <CustomButton className="loginBtn" label="Login" type="submit" />
            </div>
          </form>
        </div>

        <SnackBar
          status={state.status}
          message={state.message}
          state={state}
          setState={setState}
        />

        <AppBackdrop backdrop={state.backdrop} />
      </div>
    );
  };

  return <AppLayout>{renderLogin()}</AppLayout>;
}

export default Login;
