import React from "react";
import { StackedCarousel } from "react-stacked-carousel";
import "react-stacked-carousel/dist/index.css";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useHistory } from "react-router-dom";
import CustomButton from "../../input/Button";
import { KeyboardBackspace } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AuctionCardCarousel from "./sub-components/AuctionCardCarousel";

function AuctionOverview() {
  const history: any = useHistory();
  const { auction } = history.location.state
    ? history.location.state
    : { auction: null };
  console.log(auction);

  const goToAuction = (auction: any) => {
    history.push("/auctions/detail", { auction });
  };

  const renderAuctionOverview = () => {
    return (
      <div>
        <div className="">
          <KeyboardBackspace
            onClick={() => history.goBack()}
            className="--pointer ml-4 mt-2"
          />
          <div className="carousel-container">
            <StackedCarousel
              autoRotate={false}
              // style={{ maxWidth: "516px" }}
              // onCardChange={onCardChange}
              containerClassName={"box-container"}
              cardClassName="carouselCard"
              leftButton={
                <span
                  className="carousel-button mr-5"
                  style={{ color: "#F7931E" }}
                >
                  <ChevronLeftIcon />
                </span>
              }
              rightButton={
                <span
                  className="carousel-button ml-5"
                  style={{ color: "#F7931E" }}
                >
                  <ChevronRightIcon />
                </span>
              }
            >
              {[
                "/images/art_2.png",
                "/images/art_1.png",
                "/images/art_3.png",
              ].map((img, index) => {
                return (
                  <div key={index}>
                    <AuctionCardCarousel img={img} />
                  </div>
                );
              })}
            </StackedCarousel>
          </div>
        </div>
        <div className="content p-1 p-md-4 text-center">
          <p
            style={{
              fontWeight: 300,
              fontSize: "18px",
              lineHeight: "22px",
              color: "#3E3E3E",
            }}
          >
            Current Bid
          </p>
          <h5
            style={{
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "44px",
              color: "#000000",
            }}
          >
            $300
          </h5>
          <h5>THE STORY OF THE EYES</h5>
          <p>by Jackson Pallock</p>

          <div className="text-center py-3">
            <CustomButton
              className="viewBtn px-3 py-2 my-auto mr-4"
              label="View Details"
              type="button"
              disableElevation
              action={() => goToAuction({ status: "ongoing" })}
            />
            <CustomButton
              className="deactivateBtn px-3 py-2 my-auto"
              label="End Auction "
              type="button"
              disableElevation
            />
          </div>
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderAuctionOverview()}</DashboardLayout>;
}

export default AuctionOverview;
