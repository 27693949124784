import React, { ChangeEvent } from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
// import "../login/styles.css";
import { InputLabel, OutlinedInput } from "@material-ui/core";
import { Prop } from "../../types/prop.type";
import { VisibilityOff } from "@material-ui/icons";

function PasswordField(prop: Prop) {
  const handleChange = (targetProp: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    prop.setValues({ ...prop.values, [targetProp]: event.target.value });
  };

  const handleShowPassword = () => {
    prop.setValues({ ...prop.values, showPassword: !prop.values.showPassword });
  };

  return (
    <>
      {!prop.placeholder && <InputLabel htmlFor="password">{prop.field}</InputLabel>}
      <OutlinedInput
        placeholder="Enter your password"
        id="password"
        
        required={true}
        type={prop.values.showPassword ? "text" : "password"}
        value={prop.values.password}
        onChange={handleChange(prop.field)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
              edge="end"
            >
              {prop.values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}

export default PasswordField;
