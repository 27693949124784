import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";

export const getTransactionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_TRANSACTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/checkouts/all");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_TRANSACTION_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getCompletedTransactionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_TRANSACTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/checkouts/completed");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_COMPLETED_TRANSACTION_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error("error", error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getPendingTransactionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_TRANSACTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/checkouts/pending");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_PENDING_TRANSACTION_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const getFailedTransactionsData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_FAILED_TRANSACTION_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/checkouts/failed");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_TRANSACTION_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response?.data,
    });
  }
};

export const getTransactionDetail =
  (transactionId: string) => async (dispatch: any) => {
    dispatch({ type: "GETTING_FAILED_TRANSACTION_DATA" });

    try {
      const res = await customAxios(
        dispatch,
        "get",
        `/checkouts/${transactionId}`
      );
      // console.log(res)

      if (res.status === StatusCodes.OK) {
        await dispatch({
          type: "GET_TRANSACTION_DETAIL_DATA",
          payload: res.data.resource,
        });
      } else {
        await dispatch({
          type: "LOAD_ERROR",
          payload: "Load resource failed",
        });
      }
      return res.data.resource;
    } catch (error: any) {
      // console.error('error', error.message, error.response.status);

      await dispatch({
        type: "LOAD_ERROR",
        payload: error.response.data,
      });
    }
  };
