const initialState = {
  show: false,
  success: null,
  title: "",
  message: "",
};

export const errorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SHOW_ERRORS":
      return {
        show: true,
        success: action.payload.success,
        title: action.payload.title,
        message: action.payload.message,
      };
    case "CLEAR_ERRORS":
      return {
        show: false,
        success: null,
        title: "",
        message: "",
      };
    default:
      return {
        ...state,
        isLoading: false
      };
  }
}