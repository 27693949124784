import React, { useState } from "react";
import "./styles.css";
import { Prop } from "../../types/prop.type";
import { useHistory } from "react-router-dom";
import NavTop from "./NavTop";

const AppLayout = (prop: Prop) => {
  const [state, setState] = useState({
    showModal: false,
  });
  const history = useHistory();

  // const renderModal = () => {
  //   return (
  //     <Modal
  //       style={{ zIndex: 10000 }}
  //       centered
  //       size="lg"
  //       aria-labelledby="Modal"
  //       show={state.showModal}
  //       onHide={() => setState({ ...state, showModal: false })}
  //     >
  //       <Modal.Body>body</Modal.Body>
  //     </Modal>
  //   );
  // };

  return (
    <div className="o-appLayout">
      <div className="container-fluid px-0">
        <NavTop />
        <main className="">{prop.children}</main>
      </div>
    </div>
  );
};

export default AppLayout;
