const initialState = {
  isLoading: false,
  users: [],
  artists: [],
  artGalleries: [],
  qraters: [],
  artSuppliers: []
}

export const databaseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GETTING_DATABASE_DATA":
      return {
        ...state,
        isLoading: true
      }
    case "GET_USER_DATA":
      return {
        ...state,
        isLoading: false,
        users: action.payload.data
      }
    case "GET_ARTIST_DATA":
      return {
        ...state,
        isLoading: false,
        artists: action.payload.data
      }
    case "GET_GALLERY_DATA":
      return {
        ...state,
        isLoading: false,
        artGalleries: action.payload.data
      }
    case "GET_QRATER_DATA":
      return {
        ...state,
        isLoading: false,
        qraters: action.payload.data
      }
    case "GET_SUPPLIER_DATA":
      return {
        ...state,
        isLoading: false,
        artSuppliers: action.payload.data
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}