import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DashboardLayout from "../../layout/DashboardLayout";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import "./styles.css";
import { User } from "../../../types/user.type";
import { actions } from "..";
import Table from "../../sub components/Table";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../../store";
import AppBackdrop from "../../sub components/Backdrop";

function UsersList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { users, isLoading } = useSelector(
    (store: InitialState) => store.users
  );
  console.log(users);

  const actionsObject: any = {
    list: actions.getUsers,
  };

  // if (!users || users.length === 0) {
  //   const usersList = dispatch(getusers());
  //   console.log(usersList)
  // }

  const actionOnUser = async (action: string, user: User) => {
    try {
      if (action === "view") return actionsObject[action](user);

      dispatch(actionsObject[action](user._id));

      if (action === "delete") {
        // setusers(users.filter((e: any) => e._id !== user._id));
        console.log(users, "users");
      }
    } catch (error: any) {
      if (error.response) {
        // renderSnackbar("error", error.response.data.message);
        console.log(error.response.data.message);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      dispatch(actionsObject.list());
    } catch (error: any) {
      dispatch({ type: "USERS_LOADING_FAILED" });
      console.log(error.message);
    }
  }, []);

  const renderUsersList = () => {
    return (
      <div className="o-dashboard">
        <h5 className="">
          <PeopleAltIcon fontSize="large" />
          <span className="ml-2"> Employee List </span>
        </h5>

        {/* <Table action={actionOnUser} columns={} data={users} /> */}

        <AppBackdrop backdrop={isLoading} />
      </div>
    );
  };

  return <DashboardLayout>{renderUsersList()}</DashboardLayout>;
}

export default UsersList;
