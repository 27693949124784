import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UserProvider } from "./contexts/UserContext";
import "./App.css";
import Login from "./components/login/components";
import Signup from "./components/login/components/Signup";
import Dashboard from "./components/dashboard/components/Dashboard";
import DatabaseList from "./components/database/components/DatabaseList";
// import Layout from "./components/layout/DashboardLayout";
import UsersList from "./components/users/components/UsersList";
import { InitialState } from "./store";
import { loadUser } from "./components/login/actions";
import Settings from "./components/settings/components/Settings";
import ScrollToTop from "./components/ScrollToTop";
import Exhibitions from "./components/exhibitions/components/Exhibitions";
import Auctions from "./components/auctions/components/Auctions";
import Transactions from "./components/transactions/components/Transactions";
import DatabaseDetail from "./components/database/components/DatabaseDetail";
import TransactionDetail from "./components/transactions/components/TransactionDetail";
import AuctionDetail from "./components/auctions/components/AuctionDetail";
import AuctionOverview from "./components/auctions/components/AuctionOverview";
import AuctionsCreate from "./components/auctions/components/AuctionsCreate";
import ExhibitionsCreate from "./components/exhibitions/components/ExhibitionsCreate";
import SelectedRowsContextProvider from "./contexts/SelectedRowsContext";
import ExhibitionDetail from "./components/exhibitions/components/ExhibitionDetail";

const PrivateRoute = ({ ...route }) => {
  const user = useSelector((store: InitialState) => store.auth);
  const history = useHistory();

  return user.isAuthenticated === true ||
    ["/login", "/signup"].includes(history.location.pathname) ? (
    <Route {...route} />
  ) : (
    <Redirect to="/login" />
  );
};

function App() {
  const dispatch = useDispatch();

  const user = useSelector((store: InitialState) => store);
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const [loggedInUser, setLoggedInUser] = useState(user);

  return (
    <Switch>
      <UserProvider value={{ loggedInUser, setLoggedInUser }}>
        <SelectedRowsContextProvider>
          <ScrollToTop />
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/database" component={DatabaseList} />
          <PrivateRoute
            exact
            path="/database/detail"
            component={DatabaseDetail}
          />
          <PrivateRoute exact path="/exhibitions" component={Exhibitions} />
          <PrivateRoute
            exact
            path="/exhibitions/create"
            component={ExhibitionsCreate}
          />
          <PrivateRoute
            exact
            path="/exhibitions/detail"
            component={ExhibitionDetail}
          />
          <PrivateRoute exact path="/auctions" component={Auctions} />
          <PrivateRoute
            exact
            path="/auctions/detail"
            component={AuctionDetail}
          />
          <PrivateRoute
            exact
            path="/auctions/overview"
            component={AuctionOverview}
          />
          <PrivateRoute
            exact
            path="/auctions/create"
            component={AuctionsCreate}
          />
          <PrivateRoute exact path="/transactions" component={Transactions} />
          <PrivateRoute
            exact
            path="/transactions/detail"
            component={TransactionDetail}
          />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/users" component={UsersList} />
        </SelectedRowsContextProvider>
      </UserProvider>
    </Switch>
  );
}

export default App;
