import { combineReducers } from "redux";
import { dashboardDataReducer } from "../components/dashboard/reducers";
import { authReducer } from "../components/login/reducers";
import { usersReducer } from "../components/users/reducers";
import { errorReducer } from "../components/error/reducers";
import { databaseReducer } from "../components/database/reducers";
import { auctionReducer } from "../components/auctions/reducers";
import { exhibitionReducer } from "../components/exhibitions/reducers";
import { transactionReducer } from "../components/transactions/reducers";

export default combineReducers({
  dashboardData: dashboardDataReducer,
  auth: authReducer,
  users: usersReducer,
  database: databaseReducer,
  auctions: auctionReducer,
  exhibitions: exhibitionReducer,
  transactions: transactionReducer,
  error: errorReducer,
})