import React from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { Prop } from "../../types/prop.type";
import SmallButton from "../sub components/SmallButton";
import { List, ListItem, ListItemText, Icon } from "@material-ui/core";
import { dashboardNavItems } from "./constants/dashboardNavItems";
import { useSelector } from "react-redux";
import { InitialState } from "../../store";

const NavSide = (prop: Prop) => {
  const history = useHistory();
  const { user } = useSelector((store: InitialState) => store.auth);

  const handleClick = (path: string) => {
    history.push(path);
  };

  const activeNav = (path: string) => history.location.pathname.includes(path);

  return (
    <div className="o-sideNav w-100">
      <List className="d-flex flex-column h-100">
        <div className="a-logoContainer centerItems px-3">
          <div className="">
            <img src="/logo.png" className="img-fluid px-4" alt="logo" />
            <hr style={{ border: "0.5px solid #CCCCCC" }} />
          </div>
        </div>
        {dashboardNavItems.map((nav, index) => {
          return (
            <ListItem
              button
              key={index}
              className={activeNav(nav.path) ? "Mui-selected" : "a-navItem"}
              onClick={() => handleClick(nav.path)}
            >
              <img
                src={"/icons/nav/" + nav.icon + ".svg"}
                className="img-fluid"
                alt="icon"
              />
              <ListItemText className="ml-3" primary={nav.title} />
            </ListItem>
          );
        })}

        <ListItem button className="a-navItem mt-auto" onClick={() => prop.logout()}>
          <img
            src={"/icons/nav/log_out.svg"}
            className="img-fluid"
            alt="icon"
          />
          <ListItemText className="ml-3" primary="Log Out" />
        </ListItem>
      </List>
    </div>
  );
};

export default NavSide;
