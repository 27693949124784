export const returnErrors = (title: string, message: string, success: boolean) => (dispatch: any) => {
  dispatch({
    type: "SHOW_ERRORS",
    payload: { title, message, success }
  })
}

export const clearErrors = () => (dispatch: any) => {
  dispatch({
    type: "CLEAR_ERRORS"
  });
};
