import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useHistory } from "react-router-dom";
import DetailLayout from "../../layout/DetailLayout";
import numberToDollar from "../../utils/currencyConverter";

function TransactionDetail() {
  const history: any = useHistory();
  const { transaction } = history.location.state
    ? history.location.state
    : { transaction: null };
  console.log(transaction);

  const bgColor =
    transaction.paid === false
      ? "--yellowBg"
      : transaction.paid === true
      ? "--greenBg"
      : "--redBg";

  const renderTransactionDetail = () => {
    return (
      <div className="row">
        <div className="col-md-3 px-0 order-md-2">
          <div
            className={bgColor + " text-white col-12 py-3 text-center my-auto"}
          >
            {transaction.paid === false ? "Pending" : "Completed"}
          </div>
          <div className="centerItems py-3">
            <img
              src={
                transaction?.user?.profile_picture?.thumbnail_url ||
                "/images/profile.png"
              }
              alt="profile"
              style={{ width: "30px", borderRadius: "50%" }}
            />
            <div className="ml-3">{transaction.buyer_name}</div>
          </div>
        </div>
        <div className="col-md-9 px-0">
          <div className="py-3">
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">Contact Name</h5>{" "}
              <span className="col-1">:</span>
              {transaction.user.username}
            </div>
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">Street Address</h5>{" "}
              <span className="col-1">:</span>
              {transaction.address}
            </div>
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">Country</h5>{" "}
              <span className="col-1">:</span>
              {transaction.country}
            </div>
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">State/City</h5>{" "}
              <span className="col-1">:</span>
              {transaction.city_state}
            </div>
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">Postal Code</h5>{" "}
              <span className="col-1">:</span>
              {transaction.postal_code}
            </div>
            <div className="d-flex a-detailsTitle">
              <h5 className="a-detailsTitle__header col-5">Phone Number</h5>{" "}
              <span className="col-1">:</span>
              {transaction.phone}
            </div>
          </div>
          <div className="py-3">
            <h4
              className="a-detailsTitle__header"
              style={{ lineHeight: "40px" }}
            >
              ORDER DETAILS
            </h4>
            <div className="d-flex a-detailsTitle">
              Order No.
              <span className="col-1">:</span>
              {transaction.id}
            </div>
            <div className="d-flex a-detailsTitle">
              Expected Delivery
              <span className="col-1">:</span>
              {transaction.expectedDelivery}
            </div>
            {transaction?.products?.map((product: any, i: number) => (
              <div className="row pt-4" key={i}>
                <div className="col-md-4 pt-2">
                  <img
                    className="img-fluid"
                    src={product?.product?.file[0]?.url || "/images/pic1.png"}
                    alt="product"
                  />
                </div>
                <div className="col-md-8 a-detailsTitle">
                  <h5
                    className="a-detailsTitle__header"
                    style={{ fontSize: "18px" }}
                  >
                    {product?.product?.title}
                  </h5>
                  <p>by Jackson Pallock</p>
                  <p>{product?.product?.description}</p>
                  <div className="d-flex a-detailsTitle justify-content-between">
                    Subtotal
                    <h4 className="a-detailsTitle__header">
                      {numberToDollar(product?.sub_total || 0.0)}
                    </h4>
                  </div>
                </div>
              </div>
            ))}
            <div className="row pt-4">
              <div className="col-md-4 pt-2"></div>
              <div className="col-md-8 a-detailsTitle">
                <div className="d-flex a-detailsTitle justify-content-between">
                  Delivery Fee
                  <h4 className="a-detailsTitle__header">$2.89</h4>
                </div>
                <div
                  className="d-flex a-detailsTitle justify-content-between"
                  style={{ fontSize: "21px" }}
                >
                  <h4 className="a-detailsTitle__header">ORDER TOTAL:</h4>
                  <span style={{ color: "#F7931E" }}>
                    {numberToDollar(
                      transaction?.products?.reduce(
                        (prev: any, curr: any) =>
                          prev.sub_total + curr.sub_total
                      ) + 2.89
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <DetailLayout>{renderTransactionDetail()}</DetailLayout>
    </DashboardLayout>
  );
}

export default TransactionDetail;
