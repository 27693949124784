import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useHistory } from "react-router-dom";
import CustomButton from "../../input/Button";
import { KeyboardBackspace } from "@material-ui/icons";
import numberToDollar from "../../utils/currencyConverter";

function ExhibitionDetail() {
  const history: any = useHistory();
  const { exhibition } = history.location.state
    ? history.location.state
    : { exhibition: null };
  console.log(exhibition);

  const renderExhibitionDetail = () => {
    return (
      <div>
        <div className="banner d-flex">
          <img
            // ref={imgRef}
            className="object-cover m-auto"
            src={exhibition?.files?.[0]?.url || "/images/pic1.png"}
            alt="exhibition banner"
          />
          <section className="d-flex flex-column w-100 h-100">
            <div className="" style={{ width: "fit-content" }}>
              <KeyboardBackspace
                onClick={() => history.goBack()}
                className="--pointer m-2 text-white"
              />
            </div>
            <div className="bannerText mt-auto w-100 d-flex centerItems px-3">
              -----
            </div>
          </section>
        </div>
        <div className="content p-1 p-md-4">
          <div className="row">
            <div className="col-md-8">
              <h5>{exhibition?.name}</h5>
              <p>{Number(exhibition.ticket_price) === 0 ? "FREE" : "PAID"}</p>
            </div>
            <div className="col-md-4 text-md-right">
              <h5>Date and Time</h5>
              <p>24th Dec, 2020 <br /> 4:00pm GMT</p>
              <h5 className="mt-4">Location</h5>
              <p>Online Event</p>
            </div>
          </div>
          <h5 className="mt-5">ABOUT THIS EVENT</h5> <hr />
          <p>{exhibition?.description}</p>
          {exhibition && exhibition?.status === "requests" && (
            <>
              <h6>Requested By:</h6>
              <div className="d-flex align-items-center py-3">
                <img
                  src="/images/profile.png"
                  alt="profile"
                  style={{ width: "30px", borderRadius: "50%" }}
                />
                <div className="ml-3">artistgod</div>
              </div>
              <div className="d-flex align-items-center py-3">
                <CustomButton
                  className="sendEmailBtn px-3 py-2 my-auto mr-4"
                  label="Send Email"
                  type="button"
                  disableElevation
                />
                <CustomButton
                  className="sendEmailBtn px-3 py-2 my-auto mr-4"
                  label="Approve"
                  type="button"
                  disableElevation
                />
                <CustomButton
                  className="deactivateBtn px-3 py-2 my-auto"
                  label="Decline "
                  type="button"
                  disableElevation
                />
              </div>
            </>
          )}
          {/* {exhibition && exhibition?.status === "ongoing" && (
            <>
              <div className="d-flex align-items-center">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    lineHeight: "29px",
                  }}
                >
                  Current Bid
                </h5>
                <h5
                  className="ml-5"
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    lineHeight: "44px",
                  }}
                >
                  $300
                </h5>
              </div>
              <p className="text-danger mb-5">5 bids, reserve not met</p>

              {[0, 1, 2].map((exhibition, i) => (
                <div
                  className={
                    (i === 0 ? "topAuction" : "") +
                    " d-flex align-items-center py-3"
                  }
                  key={i}
                >
                  <img
                    src="/images/profile.png"
                    alt="profile"
                    style={{ width: "30px", borderRadius: "50%" }}
                  />
                  <div className="ml-3">artistgod</div>
                  <div className="ml-5">$200</div>
                </div>
              ))}
              <div className="d-flex align-items-center py-3">
                <CustomButton
                  className="deactivateBtn px-3 py-2 my-auto"
                  label="End Auction"
                  type="button"
                  disableElevation
                />
              </div>
            </>
          )} */}
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderExhibitionDetail()}</DashboardLayout>;
}

export default ExhibitionDetail;
