interface InitialState {
  isLoading: boolean,
  exhibitions: any[],
  requestExhibitions: any[],
  liveExhibitions: any[],
  upcomingExhibitions: any[],
  pastExhibitions: any[]
}

const initialState: InitialState = {
  isLoading: false,
  exhibitions: [],
  requestExhibitions: [],
  liveExhibitions: [],
  upcomingExhibitions: [],
  pastExhibitions: []
}

export const exhibitionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GETTING_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: true
      }
    case "GET_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: false,
        exhibitions: action.payload
      }
    case "GET_REQUEST_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: false,
        requestExhibitions: action.payload
      }
    case "GET_LIVE_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: false,
        liveExhibitions: action.payload
      }
    case "GET_UPCOMING_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: false,
        upcomingExhibitions: action.payload
      }
    case "GET_PAST_EXHIBITION_DATA":
      return {
        ...state,
        isLoading: false,
        pastExhibitions: action.payload
      }
    case "UPDATE_EXHIBITION_DATA":
      const { dispatchedAction, id } : { dispatchedAction: string, id: number } = action.payload;
      switch (dispatchedAction) {
        case "delete":
          state.pastExhibitions = state.pastExhibitions.filter(exhibition => exhibition.id !== id);
          break;
        default:
          break;
      }
      return {
        ...state,
        isLoading: false
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}