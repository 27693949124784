import React, { useState, createContext, useContext, useEffect } from "react";
import MaterialTable from "material-table";
import { Prop } from "../../../../types/prop.type";
import { Icon, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import "../styles.css";
import { useHistory } from "react-router-dom";
import { SelectedRowsContext } from "../../../../contexts/SelectedRowsContext";
// import { MuiThemeProvider } from "material-ui/styles";

const ExhibitionsTable = (prop: Prop) => {
  const data: any = prop.data;

  // Context
  const { selectedRows, setSelectedRows } = useContext(SelectedRowsContext);

  // Theme management
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#F7931E",
      },
      secondary: {
        main: "#F7931E",
      },
    },
  });

  // History
  const history = useHistory();
  console.log(selectedRows);

  // Handler functions
  const goToExhibition = (exhibition: any) => {
    history.push("/exhibitions/detail", { exhibition });
  };

  const selectionHandler = (rows: any) => {
    console.log(rows);
    // setSelectedRows(rows)
  };

  let columns = [
    {
      field: "name",
      title: "Exhibition Name",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToExhibition(rowData)}>
            {rowData.name}
          </div>
        );
      },
    },
    {
      field: "date",
      title: "Date",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToExhibition(rowData)}>
            {rowData.date}
          </div>
        );
      },
    },
    {
      field: "time",
      title: "Time ( +1:00 GMT)",
      render: (rowData: any) => {
        return (
          <div className={"--pointer "} onClick={() => goToExhibition(rowData)}>
            {rowData.time}
          </div>
        );
      },
    },
    {
      field: "address",
      title: "Location",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToExhibition(rowData)}>
            {rowData.address}
          </div>
        );
      },
    },
  ];

  // if (prop.status !== "all") {
  //   columns = columns.filter((column) => column.field !== "status");
  // }

  // Use Effect
  useEffect(() => {
    setSelectedRows([]);
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          options={{
            emptyRowsWhenPaging: false,
            selection: true,
            selectionProps: () => ({
              color: 'primary'
            }),
            rowStyle: (row) => ({
              backgroundColor: [selectedRows].find(
                (selectedRow: any) => selectedRow._id === row._id
              )
                ? "rgba(255, 217, 173, 0.2)"
                : "#FFF",
            }),
          }}
          style={{
            borderRadius: 0,
            boxShadow: "none",
            backgroundColor: "transparent",
            padding: "25px",
          }}
          page={10}
          columns={columns}
          data={data}
          title=""
          onSelectionChange={selectionHandler}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ExhibitionsTable;
