import React, { useState, createContext, useEffect } from "react";
import MaterialTable from "material-table";
import { Prop } from "../../../../types/prop.type";
import { Icon } from "@material-ui/core";
import "../styles.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionsData,
  getCompletedTransactionsData,
  getPendingTransactionsData,
  getFailedTransactionsData,
} from "../../actions";
import { InitialState } from "../../../../store";
import numberToDollar from "../../../utils/currencyConverter";

interface Contants {
  [key: string]: any;
}

const TransactionsTable = (prop: Prop) => {
  const history = useHistory();
  // State management
  const {
    transactions,
    completedTransactions,
    pendingTransactions,
    failedTransactions,
  } = useSelector((store: InitialState) => store.transactions);
  // constants
  const status: string = prop.status;
  const actions: Contants = {
    all: getTransactionsData,
    completed: getCompletedTransactionsData,
    pending: getPendingTransactionsData,
    failed: getFailedTransactionsData,
  };
  const data: Contants = {
    all: transactions,
    completed: completedTransactions,
    pending: pendingTransactions,
    failed: failedTransactions,
  };
  // Dispatch
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(actions[status] ? actions[status]() : { type: "LOAD_ERROR" });
  }, []);

  const textColors: any = {
    Completed: "text-success",
    Failed: "text-danger",
    Pending: "text-warning",
  };

  const goToTransaction = (transaction: any) => {
    history.push("/transactions/detail", { transaction });
  };

  let columns = [
    {
      field: "orderNo",
      title: "Order No.",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToTransaction(rowData)}>
            {rowData.id}
          </div>
        );
      },
    },
    {
      field: "name",
      title: "Contact Name",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToTransaction(rowData)}>
            {rowData?.user?.username}
          </div>
        );
      },
    },
    {
      field: "phone",
      title: "Contact Number",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToTransaction(rowData)}>
            {rowData.phone}
          </div>
        );
      },
    },
    {
      field: "status",
      title: "Status",
      render:
        prop.status === "all"
          ? (rowData: any) => {
              return (
                <div
                  className={
                    "--pointer " +
                      textColors[rowData.paid ? "Completed" : "Pending"] || ""
                  }
                  onClick={() => goToTransaction(rowData)}
                >
                  {rowData.paid ? "Completed" : "Pending"}
                </div>
              );
            }
          : undefined,
    },
    {
      field: "order",
      title: "Order",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToTransaction(rowData)}>
            {numberToDollar(
              rowData?.products?.reduce(
                (prev: any, curr: any) => prev.sub_total + curr.sub_total
              )
            )}
          </div>
        );
      },
    },
    {
      field: "expectedDelivery",
      title: "Expected Delivery",
      render: (rowData: any) => {
        return (
          <div className="--pointer" onClick={() => goToTransaction(rowData)}>
            {rowData.expectedDelivery}
          </div>
        );
      },
    },
  ];

  if (prop.status !== "all") {
    columns = columns.filter((column) => column.field !== "status");
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        options={{
          emptyRowsWhenPaging: false,
        }}
        style={{
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
        page={10}
        columns={columns}
        data={data[status] || []}
        title=""
      />
    </div>
  );
};

export default TransactionsTable;
