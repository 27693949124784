import axios from "axios";
import { baseApiUrl } from ".";
import { Storage } from "../components/utils/storage";
import { StatusCodes } from "http-status-codes";
import { returnErrors } from "../components/error/actions";

export type method = "get" | "post" | "put";

export const customAxios = (
  dispatch: any,
  method: method,
  url: string,
  body?: any
) => {
  const user = Storage.getItem("qrate-admin");
  let token: string | null = user ? user.access_token : null;

  axios.interceptors.response.use(
    (res) => {
      // console.log(res)
      if (res.status === StatusCodes.UNAUTHORIZED) {
        dispatch({ type: "AUTH_ERROR" });
        // window.location.href = "/login";
      }
      if (res.status >= 400) {
        dispatch(returnErrors("Error", "An error occured", false));
      }
      return res;
    },
    (error) => {
      // console.log(
      //   error.message,
      //   error,
      //   error.status,
      //   error.statusCode,
      //   error.response
      // );
      if (error.message === "Request failed with status code 404") {
        dispatch(returnErrors("Error", "Resource not found", false));
      } else dispatch(returnErrors("Error", "An error occured", false));
      return error;
    }
  );

  return axios.request({
    baseURL: baseApiUrl,
    url,
    method,
    headers: {
      Accept: "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    data: body,
  });
};
