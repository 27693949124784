import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";

export const getUsersData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_DATABASE_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/users");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_USER_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const activateUser = (userId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_DATABASE_DATA" });

  try {
    const res = await customAxios(dispatch, "post", `/activate/user/${userId}`);
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_USER_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "User activation failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};

export const deactivateUser = (userId: string) => async (dispatch: any) => {
  dispatch({ type: "GETTING_DATABASE_DATA" });

  try {
    const res = await customAxios(
      dispatch,
      "post",
      `/deactivate/user/${userId}`
    );
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "UPDATE_USER_DATA",
        payload: res.data.resource,
      });

      return res.data.resource;
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "User deactivation failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};
