import { customAxios } from "../../constants/axios";
import { StatusCodes } from "http-status-codes";

export const getUserProfile = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_USER_PROFILE" });

  try {
    await dispatch({
      type: "GET_USER_PROFILE",
      payload: { data: "user data" },
    });
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "AUTH_ERROR",
      payload: error.response.data,
    });
  }
};

export const getDashboardData = () => async (dispatch: any) => {
  dispatch({ type: "GETTING_DASHBOARD_DATA" });

  try {
    const res = await customAxios(dispatch, "get", "/dashboard");
    // console.log(res)

    if (res.status === StatusCodes.OK) {
      await dispatch({
        type: "GET_DASHBOARD_DATA",
        payload: res.data.resource,
      });
    } else {
      await dispatch({
        type: "LOAD_ERROR",
        payload: "Load resource failed",
      });
    }
  } catch (error: any) {
    // console.error('error', error.message, error.response.status);

    await dispatch({
      type: "LOAD_ERROR",
      payload: error.response.data,
    });
  }
};
