import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Prop } from "../../types/prop.type";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackBar = (prop: Prop) => {
  const { status, message, state, setState } = prop;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (state.snackBarOpened) {
      handleOpen();
    }
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
    setState({ ...state, snackBarOpened: false });
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={status}>
        {message}
      </Alert>
    </Snackbar>
  );
};
