const initialState = {
  isLoading: false,
  users: {
    artists: 0,
    galleries: 0,
    qraters: 0,
    supplies: 0,
    total: 0
  },
  artworks: {
    percentage_per_category: [],
    total: 0
  }
}

export const dashboardDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GETTING_DASHBOARD_DATA":
      return {
        ...state,
        isLoading: true
      }
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
        artworks: action.payload.artworks
      }
    default:
      return {
        ...state,
        isLoading: false
      }
  }
}