import React, { useEffect } from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import ExhbitionsCard from "./ExhbitionsCard";
import ExhibitionsTable from "./ExhibitionsTable";
import { Constants } from "../../../auctions/components/sub-components/AuctionsCard";
import { useSelector, useDispatch } from "react-redux";
import { InitialState } from "../../../../store";
import {
  getExhibitionsData,
  getExhibitionRequestsData,
  getExhibitionLiveData,
  getExhibitionUpcomingData,
  getExhibitionPastData,
} from "../../actions";

interface StyledTabProps {
  label?: string;
  className?: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<{}>, newValue: number) => void;
  variant?: any;
  scrollButtons?: any;
}

// const StyledTabs = withStyles({
//   indicator: {
//     display: "flex",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//     "& > span": {
//       maxWidth: "100%",
//       width: "100%",
//       backgroundColor: "var(--yellow)",
//     },
//   },
// })((props: StyledTabProps) => (
//   <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
// ));

// const StyledTab = withStyles((theme: Theme) => ({
//   root: {
//     textTransform: "none",
//     color: "var(--black)",
//     fontWeight: theme.typography.fontWeightRegular,
//     fontSize: theme.typography.pxToRem(15),
//     // marginRight: theme.spacing(1),
//     "&:focus": {
//       opacity: 1,
//     },
//   },
// }))((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsContent: {
    padding: "0px",
    // backgroundColor: 'var(--grey)',
  },
  tabs: {
    backgroundColor: "transparent",
    borderBottom: "0.6px solid #C4C4C4",
    // boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.05)",
    // borderRadius: "5px",
  },
}));

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ExhibitionsTab({ value, setValue }: any) {
  // Styles
  const classes = useStyles();

  // State management
  const {
    exhibitions,
    requestExhibitions,
    liveExhibitions,
    upcomingExhibitions,
    pastExhibitions,
  } = useSelector((store: InitialState) => store.exhibitions);
  // constants
  const actions: Constants = {
    all: getExhibitionsData,
    requests: getExhibitionRequestsData,
    live: getExhibitionLiveData,
    upcoming: getExhibitionUpcomingData,
    past: getExhibitionPastData,
  };
  const data: Constants = {
    all: exhibitions,
    requests: requestExhibitions,
    live: liveExhibitions,
    upcoming: upcomingExhibitions,
    past: pastExhibitions,
  };
  // Dispatch
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(actions.all());
    dispatch(actions.requests());
    dispatch(actions.live());
    dispatch(actions.upcoming());
    dispatch(actions.past());
  }, []);

  // Handler functions
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root + " tab"}>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="tabs"
      >
        <Tab label="Requests" />
        <Tab label="Live" />
        <Tab label="Upcoming" />
        <Tab label="Past" />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <ExhibitionsTable status="requests" data={data.requests} />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <ExhbitionsCard status="live" data={data.live} />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabsContent}>
        <ExhibitionsTable status="upcoming" data={data.upcoming} />
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabsContent}>
        <ExhibitionsTable status="past" data={data.past} />
      </TabPanel>
    </div>
  );
}
