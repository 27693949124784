import React, { ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import { Prop } from "../../types/prop.type";
import "./styles.css";
import { InputAdornment, Icon } from "@material-ui/core";

function InputField(prop: Prop) {
  const handleChange = (targetProp: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    prop.setValues({ ...prop.values, [targetProp]: event.target.value });
  };

  return (
    <>
      <TextField
        className={prop.className + " a-inputField"}
        variant={prop.variant}
        type={prop.type ? prop.type : prop.field === "Email" ? "email" : "text"}
        required={prop.required}
        placeholder={prop.placeholder}
        label={!prop.placeholder ? prop.label || prop.field : null}
        value={prop.values[prop.field]}
        onChange={handleChange(prop.field)}
        InputProps={{
          endAdornment: (
            <InputAdornment className="px-2" position="end">
              <Icon>{prop.icon}</Icon>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default InputField;
