import React from "react";
import "./styles.css";
import DashboardLayout from "../../layout/DashboardLayout";
import { useHistory } from "react-router-dom";
import CustomButton from "../../input/Button";
import { KeyboardBackspace } from "@material-ui/icons";
import numberToDollar from "../../utils/currencyConverter";

function AuctionDetail() {
  const history: any = useHistory();
  const { auction } = history.location.state
    ? history.location.state
    : { auction: null };
  console.log(auction);
  const artwork: any = auction?.auction?.artwork;

  const renderAuctionDetail = () => {
    return (
      <div>
        <div className="banner d-flex">
          <img
            // ref={imgRef}
            className="object-cover m-auto"
            src={artwork.files?.[0]?.url || "/images/pic1.png"}
            alt="auction banner"
          />
          <section className="d-flex flex-column w-100 h-100">
            <div className="" style={{ width: "fit-content" }}>
              <KeyboardBackspace
                onClick={() => history.goBack()}
                className="--pointer m-2 text-white"
              />
            </div>
            <div className="bannerText mt-auto w-100 d-flex centerItems px-3">
              -----
            </div>
          </section>
        </div>
        <div className="content p-1 p-md-4">
          <h5>{artwork.title}</h5>
          <p>by {artwork?.user?.username}</p>
          <h6>DESCRIPTION</h6> <hr />
          <p>
            {artwork.description}
          </p>
          <h6>FRAME</h6> <hr />
          <p>70 × 45 cm, Glossed art print</p>
          <h6>MINIMUM BID</h6> <hr />
          <p>{numberToDollar(auction?.auction?.start_bid)}</p>
          <h6>AUCTION STARTS</h6> <hr />
          <p>{auction?.auction?.starts}</p>
          <h6>AUCTION ENDS</h6> <hr />
          <p>{auction?.auction?.ends}</p>
          {auction && auction.status === "requests" && (
            <>
              <h6>Requested By:</h6>
              <div className="d-flex align-items-center py-3">
                <img
                  src="/images/profile.png"
                  alt="profile"
                  style={{ width: "30px", borderRadius: "50%" }}
                />
                <div className="ml-3">artistgod</div>
              </div>
              <div className="d-flex align-items-center py-3">
                <CustomButton
                  className="sendEmailBtn px-3 py-2 my-auto mr-4"
                  label="Send Email"
                  type="button"
                  disableElevation
                />
                <CustomButton
                  className="sendEmailBtn px-3 py-2 my-auto mr-4"
                  label="Approve"
                  type="button"
                  disableElevation
                />
                <CustomButton
                  className="deactivateBtn px-3 py-2 my-auto"
                  label="Decline "
                  type="button"
                  disableElevation
                />
              </div>
            </>
          )}
          {auction && auction.status === "ongoing" && (
            <>
              <div className="d-flex align-items-center">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    lineHeight: "29px",
                  }}
                >
                  Current Bid
                </h5>
                <h5
                  className="ml-5"
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    lineHeight: "44px",
                  }}
                >
                  $300
                </h5>
              </div>
              <p className="text-danger mb-5">5 bids, reserve not met</p>

              {[0, 1, 2].map((auction, i) => (
                <div
                  className={
                    (i === 0 ? "topAuction" : "") +
                    " d-flex align-items-center py-3"
                  }
                  key={i}
                >
                  <img
                    src="/images/profile.png"
                    alt="profile"
                    style={{ width: "30px", borderRadius: "50%" }}
                  />
                  <div className="ml-3">artistgod</div>
                  <div className="ml-5">$200</div>
                </div>
              ))}
              <div className="d-flex align-items-center py-3">
                <CustomButton
                  className="deactivateBtn px-3 py-2 my-auto"
                  label="End Auction"
                  type="button"
                  disableElevation
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return <DashboardLayout>{renderAuctionDetail()}</DashboardLayout>;
}

export default AuctionDetail;
